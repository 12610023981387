.hooksMain > div {
  position: absolute;
  will-change: transform;
  border-radius: 50%;
  z-index: 2;
  background: rgba(205, 2, 2, 0.753);
  /* background: lightcoral; */
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  opacity: .8;
}

.hooksMain > div:nth-child(1) {
  width: 60px;
  height: 60px;
}

.hooksMain > div:nth-child(2) {
  width: 120px;
  height: 120px;
}

.hooksMain > div:nth-child(3) {
  width: 75px;
  height: 75px;
}

.hooksMain > div::after {
  content: '';
  position: absolute;
  top: 20px;
  left: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.8);
}

.hooksMain > div:nth-child(2)::after {
  top: 35px;
  left: 35px;
  width: 35px;
  height: 35px;
}

.hooksMain > div:nth-child(3)::after {
  top: 25px;
  left: 25px;
  width: 25px;
  height: 25px;
}

.hooksMain {
  position: absolute;
  width: 100%;
  height: 100%;
  filter: url('#goo');
  overflow: hidden;
  background: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}


.containerb {
  background: transparent;
  cursor: url('https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/Ad1_-cursor.png') 39 39,
    auto;
  display: flex;
  align-items: center;
  height: fit-content;
  padding: 35vh 0;
  justify-content: center;
}
.deck {
  position: absolute;
  width: 300px;
  height: 200px;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: none;
}

.deck > div {
  background-color: white;
  background-size: auto 85%;
  background-repeat: no-repeat;
  background-position: center center;
  width: 45vh;
  max-width: 150px;
  height: 85vh;
  max-height: 285px;
  will-change: transform;
  border-radius: 10px;
  box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3);
}