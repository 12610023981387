// 90 = 360/no of layers (4 in my case)
$hueStep: 90;
$delayStep: .115s;


.btnn {
  background: hsl(var(blue), 98%, 80%);
//   border: none;
  border-radius: 7px;
  cursor: pointer;
  color: white;
  font: 1.05rem/1 "Nunito", sans-serif;
  letter-spacing: 1.05em;
  overflow: hidden;
  padding: .15em 2.5em;
  min-height: 5.3em;
  position: relative;
//   text-transform: lowercase;

  &--yellow {
    --hue: 46;
  }

  &--green {
    --hue: 163;
  }

  &--purple {
    --hue: 244;
  }

  &--red {
    --hue: 0;
  }

  &--blue {
    --hue: 210;
  }

  &:active,
  &:focus {
    outline: 3px solid hsl(calc(var(--hue) + #{$hueStep}), 98%, 80%);
  }

  & + & {
    margin-top: 2.5em;
  }

  &__txt {
    position: relative;
    z-index: 2;
  }

  &__bg {
    background: hsl(var(--hueBg), 98%, 80%);
    border-radius: 50%;
    display: block;
    height: 0;
    left: 50%;
    margin: -50% 0 0 -50%;
    padding-top: 100%;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: scale(0);
    transform-origin: 50% 50%;
    transition: transform 0.175s cubic-bezier(0.5, 1, 0.89, 1); 
    z-index: 1;

    @for $i from 1 through 4 { 
      &:nth-of-type(#{$i}) {
        --hueBg: calc(var(--hue) - #{$i * $hueStep});
        transition-delay: $delayStep / 2 * (4 - $i);
      }
    }

    .btnn:hover &,
    .btnn:focus &,
    .btnn:active & {
      transform: scale(1.5);
      transition: transform 0.35s cubic-bezier(0.11, 0, 0.5, 0);

      @for $i from 1 through 4 {
        &:nth-of-type(#{$i}) {
          transition-delay: $delayStep * $i;
        }
      }
    }
  }
}