.btnn {
  background: hsl(var(blue), 98%, 80%);
  border-radius: 7px;
  cursor: pointer;
  color: white;
  font: 1.05rem/1 "Nunito", sans-serif;
  letter-spacing: 1.05em;
  overflow: hidden;
  padding: 0.15em 2.5em;
  min-height: 5.3em;
  position: relative;
}
.btnn--yellow {
  --hue: 46;
}
.btnn--green {
  --hue: 163;
}
.btnn--purple {
  --hue: 244;
}
.btnn--red {
  --hue: 0;
}
.btnn--blue {
  --hue: 210;
}
.btnn:active, .btnn:focus {
  outline: 3px solid hsl(calc(var(--hue) + 90), 98%, 80%);
}
.btnn + .btnn {
  margin-top: 2.5em;
}
.btnn__txt {
  position: relative;
  z-index: 2;
}
.btnn__bg {
  background: hsl(var(--hueBg), 98%, 80%);
  border-radius: 50%;
  display: block;
  height: 0;
  left: 50%;
  margin: -50% 0 0 -50%;
  padding-top: 100%;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: scale(0);
  transform-origin: 50% 50%;
  transition: transform 0.175s cubic-bezier(0.5, 1, 0.89, 1);
  z-index: 1;
}
.btnn__bg:nth-of-type(1) {
  --hueBg: calc(var(--hue) - 90);
  transition-delay: 0.1725s;
}
.btnn__bg:nth-of-type(2) {
  --hueBg: calc(var(--hue) - 180);
  transition-delay: 0.115s;
}
.btnn__bg:nth-of-type(3) {
  --hueBg: calc(var(--hue) - 270);
  transition-delay: 0.0575s;
}
.btnn__bg:nth-of-type(4) {
  --hueBg: calc(var(--hue) - 360);
  transition-delay: 0s;
}
.btnn:hover .btnn__bg, .btnn:focus .btnn__bg, .btnn:active .btnn__bg {
  transform: scale(1.5);
  transition: transform 0.35s cubic-bezier(0.11, 0, 0.5, 0);
}
.btnn:hover .btnn__bg:nth-of-type(1), .btnn:focus .btnn__bg:nth-of-type(1), .btnn:active .btnn__bg:nth-of-type(1) {
  transition-delay: 0.115s;
}
.btnn:hover .btnn__bg:nth-of-type(2), .btnn:focus .btnn__bg:nth-of-type(2), .btnn:active .btnn__bg:nth-of-type(2) {
  transition-delay: 0.23s;
}
.btnn:hover .btnn__bg:nth-of-type(3), .btnn:focus .btnn__bg:nth-of-type(3), .btnn:active .btnn__bg:nth-of-type(3) {
  transition-delay: 0.345s;
}
.btnn:hover .btnn__bg:nth-of-type(4), .btnn:focus .btnn__bg:nth-of-type(4), .btnn:active .btnn__bg:nth-of-type(4) {
  transition-delay: 0.46s;
}/*# sourceMappingURL=blob.css.map */